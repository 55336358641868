























import Vue from "vue";
import {
  getAllResources,
  allocateRoleResources,
  getRoleResources,
  categoryAll
} from "@/api/resource";
import { Tree } from "element-ui";

export default Vue.extend({
  name: "AllocResource",
  props: {
    roleId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      resources: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      defaultCheckedKeys: []
    };
  },
  created() {
    this.loadResources();
    this.loadRoleResources();
  },
  methods: {
    async loadResources() {
      const ret = await Promise.all([getAllResources(), categoryAll()]);
      const resources = ret[0].data.data;
      const resourceCategories = ret[1].data.data;

      resources.forEach((r: any) => {
        const category = resourceCategories.find((c: any) => c.id === r.categoryId);
        if (category) {
          category.children = category.children || [];
          category.children.push(r);
        }
      });
      // 修改顶层分类 ID：因为分类 ID 和资源 ID 冲突
      resourceCategories.forEach((item: any) => {
        item.id = Math.random();
      });

      this.resources = resourceCategories;
    },

    async loadRoleResources() {
      const { data } = await getRoleResources(this.roleId);
      this.getCheckedResources(data.data);
    },

    getCheckedResources(resources: any) {
      resources.forEach((r: any) => {
        r.resourceList &&
          r.resourceList.forEach((c: any) => {
            if (c.selected) {
              this.defaultCheckedKeys = [...this.defaultCheckedKeys, c.id] as any;
            }
          });
      });
    },

    async onSave() {
      const checkedNodes = (this.$refs.tree as Tree).getCheckedNodes();
      const resourceIdList: number[] = [];
      checkedNodes.forEach((item) => {
        if (!item.children) {
          resourceIdList.push(item.id);
        }
      });
      await allocateRoleResources({
        roleId: this.roleId,
        resourceIdList
      });
      this.$message.success("保存成功");
      this.$router.back();
    }
  }
});
