























import Vue from "vue";
import { getMenuList, allocateRoleMenus, getRoleMenus } from "@/api/menu";
import { Tree } from "element-ui";

export default Vue.extend({
  name: "AllocMenu",
  props: {
    roleId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      menus: [],
      defaultProps: {
        children: "subMenuList",
        label: "name"
      },
      checkedKeys: []
    };
  },

  async created() {
    await this.loadMenus();
    this.loadRoleMenus();
  },

  methods: {
    async loadRoleMenus() {
      const { data } = await getRoleMenus(this.roleId);
      this.getCheckedKeys(data.data);
      console.log(this.checkedKeys);
    },

    getCheckedKeys(menus: any) {
      menus.forEach((menu: any) => {
        if (menu.selected) {
          // this.checkedKeys.push(menu.id as never)
          this.checkedKeys = [...this.checkedKeys, menu.id] as any;
        }
        if (menu.subMenuList) {
          this.getCheckedKeys(menu.subMenuList);
        }
      });
    },

    async loadMenus() {
      const { data } = await getMenuList();
      this.menus = data.data;
    },

    async onSave() {
      const menuIdList = (this.$refs["menu-tree"] as Tree).getCheckedKeys();
      // 拿到选中节点的数据 id 列表
      // 请求提交保存
      await allocateRoleMenus({
        roleId: this.roleId,
        menuIdList
      });
      this.$message.success("操作成功");
      this.$router.back();
    }
  }
});
